<template>
  <div>
    <div class="modal fade" id="create-event" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <form class="form" @submit.prevent="submit">
          <div class="modal-content">
            <div class="modal-header modal-header-blue-bg">
              <div class="title-with-icon">
                <div class="title-with-icon__icon title-with-icon__icon-white  title-with-icon__icon_tag"></div>
                <div class="title-with-icon__title">Создать мероприятие</div>
              </div>
              <button id="modal-close" type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="d-flex justify-content-end align-items-center mt-4">
                <div class="checkbox">
                  <input id="is-secret" value="" type="checkbox"/>
                  <label for="is-secret">Программа только для тренеров (закрытое мероприятие)</label>
                </div>
              </div>
              <div class="form-separator"></div>
              <div class="row">
                <div class="col-lg-4 col-md-6 d-flex">
                  <label class="flex-one">
                    Иллюстрация<br/> мероприятия
                  </label>
                  <div class="event-avatar" :style="{backgroundImage: 'url(' + avatar + ')'}"></div>
                </div>
                <div class="col-lg-8 col-md-6">
                  <document-gray title="Инструкция тренера очного обучения" link="/docs/recommend.pdf" />
                </div>
              </div>
              <div class="form-separator"></div>
              <div class="form-item form-item_groupped form-item_groupped_required">
                <label for="company_activity_field"><span>Название мероприятия</span></label>
                <input v-validate.immediate="'required'" :class="{'invalid': errors.has('company_activity_field')}" :value="course.seminar_name" :disabled="true" class="form-control" type="text" name="company_activity_field" id="company_activity_field" required/>
              </div>
              <div class="form-item form-item_groupped form-item_groupped_required">
                <label for="type"><span>Тип мероприятия</span></label>
                <sweet-select
                    v-validate.immediate="'required'"
                    :class="{invalid: errors.has('type')}"
                    name="type"
                    id="type"
                    :options="types"
                    item-title="title"
                    item-value="id"
                    v-model="form.type" />
              </div>
              <div class="form-separator"></div>
              <div class="form-item form-item_groupped form-item_groupped_required">
                <label for="type"><span>Даты проведения мероприятия</span></label>
                <sweet-date-picker :class="{'invalid': errors.has('date')}" name="date" v-validate.immediate="'required'" v-model="form.dates" />
              </div>
              <div class="form-separator"></div>
              <div class="form-item form-item_groupped form-item_groupped_required">
                <label for="type"><span>Тренер мероприятия</span></label>
                <div class="form-item_groupped__with_icon">
                  <img src="/pic/icon-input-user.svg"/>
                  <autocomplete :class="{'invalid': errors.has('fullname')}" path="tutors?response=standard&search=" label="Выберите тренера" placeholder="ФИО" name="fullname" v-model="form.tutor" />
                </div>
              </div>
              <div class="form-item form-item_groupped contacts">
                <label for="type"><span>Контактные лица</span></label>
                <div class="row m-0">
                  <div class="col-md-12 col-lg-6 pl-lg-0">
                    <div class="form-item_groupped__with_icon">
                      <img src="/pic/icon-input-user-exclamation.svg"/>
                      <autocomplete v-model="form.tutorsContact1" path="tutors?response=standard&search=" label="Выберите контакт" placeholder="ФИО" value-name="fullname" :validate="false" name="tutorsContact1"/>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-6 pr-lg-0">
                    <div class="form-item_groupped__with_icon">
                      <img src="/pic/icon-input-user-exclamation.svg"/>
                      <autocomplete v-model="form.tutorsContact2" path="tutors?response=standard&search=" value-name="fullname" label="Выберите контакт" placeholder="ФИО" name="tutorsContact2" :validate="false" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-item form-item_groupped form-item_groupped_required">
                <label for="type"><span>Город</span></label>
                <div class="form-item_groupped__with_icon">
                  <img src="/pic/icon-input-home.svg"/>
                  <autocomplete v-validate.immediate="'required'" :class="{'invalid': errors.has('city_name_ru')}" v-model="form.city" placeholder="Начните вводить город" />
                </div>
              </div>
              <div class="form-item form-item_groupped form-item_groupped_required">
                <label for="type"><span>Место</span></label>
                <div class="form-item_groupped__with_icon">
                  <img src="/pic/icon-input-place.svg"/>
                  <input v-validate.immediate="'required'" v-model="form.location" name="place" :class="{'invalid': errors.has('place')}" class="form-control" placeholder="Введите место" type="text" id="place" required/>
                </div>
              </div>
              <div class="form-separator"></div>
              <div class="form-item form-item_groupped form-item_groupped_required">
                <label for="type"><span>Описание</span></label>
                <textarea v-validate.immediate="'required'" v-model="form.description" :class="{'invalid': errors.has('desc')}" name="desc" class="form-control" placeholder="Введите описание"></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <div class="d-lg-flex width_100 justify-content-between align-items-center">
                <div class="checkbox">
                  <input id="is-secret" v-model="confirm" type="checkbox"/>
                  <label for="is-secret">Подтверждаю правильность заполнения сведений</label>
                </div>
                <button class="btn btn-primary d-sm-block" :disabled="!confirm">
                  <img src="/pic/icon-plus.svg" />
                  Создать мероприятие
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <a @click="showModal" class="link-underline hidden-md hidden-sm" href="javascript:void(0)">Создать мероприятие</a>&nbsp;
    <img @click="showModal" src="/pic/profile/icon-add.svg" />
  </div>
</template>

<script>
import $ from 'jquery'
import 'bootstrap'
import SweetSelect from "@/components/form/SweetSelect.vue";
import SweetDatePicker from "@/components/form/SweetDatePicker.vue";
import {TokenService} from "../../../../services/token.service";
import Autocomplete from "@/components/Autocomplete.vue";
import DocumentGray from "@/components/DocumentGray.vue";
import ApiService from "../../../../services/api.service";
import {eventBus} from "@/main";
import moment from 'moment-timezone';
import 'moment/locale/ru'
moment.locale('ru')
export default {
  name: "CreateEvent",
  components: {DocumentGray, Autocomplete, SweetDatePicker, SweetSelect},
  props: {
    course: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      confirm: false,
      user: JSON.parse(TokenService.getUser()),
      form: {
        title: this.course.seminar_name,
        course: this.course.id,
        tutor: null,
        avatar: null,
        type: '',
        tutorsContact1: null,
        tutorsContact2: null,
        tutorsContact: []
      },
      types: [
        {
          id: 'lecture',
          title: 'Лекция'
        },
        {
          id: 'seminar',
          title: 'Экспортный семинар'
        },
        {
          id: 'accelerator',
          title: 'Акселератор'
        },
        {
          id: 'accelerator_concurs',
          title: 'Форсаж'
        }
      ]
    }
  },
  beforeMount() {
    if(this.$hasRole('ROLE_SUPER_ADMIN')) {
      this.types.push({
        id: 'online-test',
        title: 'Очная аттестация по онлайн-программе'
      });
    }
  },
  mounted() {
    this.form.tutor = {
      id: this.user.tutor_id,
      fullname: this.user.fullname
    }
    this.form.tutorsContact1 = this.form.tutor
  },
  methods: {
    init() {
      this.form = {
          title: this.course.seminar_name,
          course: this.course.id,
          tutor: null,
          avatar: null,
          tutorsContact1: null,
          tutorsContact2: null,
          tutorsContact: []
      }
    },
    showModal() {
      $('#create-event').modal('show')
    },
    async submit() {
      const res = await this.$validator.validateAll()
      if(res) {
        try {

          let result = await ApiService.postJson('events', {
            ...this.form,
            tutorsContact: this.tutorsContact,
            tutors: [this.form.tutor.id],
            city: this.form.city.id,
            isActive: true,
            dates: this.form.dates
          })
          if(result.status === 200) {
            $('#create-event').modal('hide')
            eventBus.$emit('event:create', result.data.id)
            this.$toastr.s('', "Мероприятие успешно создано");
          } else {
            throw new Error('Ошибка!')
          }
        } catch (e) {
          console.error(e.message)
          this.$toastr.e('', "Проверьте правильность заполнения полей");
        }
      } else {
        this.$toastr.e('', "Проверьте правильность заполнения полей!");
      }
    }
  },
  computed: {
    tutorsContact() {
      let contacts = [];
      if(this.form.tutorsContact1) {
        contacts.push(this.form.tutorsContact1.id)
      }
      if(this.form.tutorsContact2) {
        contacts.push(this.form.tutorsContact2.id)
      }
      return contacts
    },
    city() {
      return this.form.city
    },
    avatar() {
      return this.form.avatar ? this.form.avatar["500x"] : '/pic/profile/event-avatar.svg';
    }
  },
  watch: {
    city(val) {
      if(val && val.region) {
        this.form.avatar = val.region.avatar ? val.region.avatar : null;
      }
    },
    course: {
      handler: function(val) {
        if(val) {
          this.form.course = val;
          this.form.title = val.seminar_name;
        }
      },
      deep: true,
      immediate: true
    }
  }

}
</script>

<style lang="scss">
.event-avatar {
  width: 14.8rem;
  height: 20.4rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.chosen-container {
  width: calc(100% - 23.2rem)!important;
}
</style>